import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { showModalSuccess } from "../../../redux/modalTypeSuccess"

// ** Axios Imports
import api from "@src/api/config"
import { getCommisions } from "./index"
import { getHistory } from "./history"

export const getRefunds = createAsyncThunk(
    "refunds/get",
    async (params) => {
      const response = await api.get(`api/refunds`, { params })
      return {
        data: response.data,
        params
      }
    }
)
  
export const getSellerRefunds = createAsyncThunk(
    "refunds/getSellerRefunds",
    async ({ user_id, cutoff, params }) => {
      const response = await api.get(`api/refunds/${user_id}`, {
        params: {
          ...params,
          cutoff
        }
      })
      return {
        data: response.data,
        params
      }
    }
)

export const payRefunds = createAsyncThunk(
  "refunds/pay",
  async ({ params, toggle, setClearRows }, { dispatch }) => {
    const response = await api.post(`api/refunds/pay`, params)
    if (response.data.status === "success") {
      const url = `/accountants/commissions`
      dispatch(showModalSuccess({ message: response.data.message, url }))
      toggle()
      setClearRows(true)
      dispatch(getCommisions({ page: 1, product: params.product }))
      dispatch(getRefunds({ page: 1, product: params.product }))
      dispatch(getHistory({ page: 1, product: params.product }))
    }
    return {
      data: response.data,
      params
    }
  }
)

export const refundsSlice = createSlice({
    name: "refunds",
    initialState: {
      data: [],
      suggestions: {
        seller: []
      },
      details: [],
      detailsSuggestions: {
          invoice: [],
          taxdata: []
      },
      detailsResume: null,
      params: {
        page: 1, // current page
        orderBy: 'folio',
        sort: 'desc',
        range: null,
        seller: null
      },
      paramsDetails: {
        page: 1,
        orderBy: 'folio',
        sort: 'desc',
        invoice: null,
        taxdata: null
      },
      seller_name: "",
      startDate: null,
      endDate: null,
      search_key: 'seller',
      search_label: 'Vendedor',
      search_key_details: 'invoice',
      search_label_details: 'Factura',
      current_refunds_status: "idle",
      rows_per_page: 10,
      total_rows: 1,
      current_page: 1,
      last_page: 1
    },
    reducers: {
        updateRange: (state, action) => {
          // console.debug("updateRange", action.payload)
          state.startDate = action.payload.range[0]
          state.endDate = action.payload.range[1]
        },
        setParams: (state, action) => {
          // console.debug('Payload:', action.payload)
          state.params = action.payload
        },
        setParamsDetails: (state, action) => {
          // console.debug('Payload:', action.payload)
          state.paramsDetails = action.payload
        },
        setFilterSelected: (state, action) => {
          state.search_key = action.payload?.key
          state.search_label = action.payload?.label
        },
        setFilterDetailsSelected: (state, action) => {
          state.search_key_details = action.payload?.key
          state.search_label_details = action.payload?.label
        }
    },
    extraReducers: (builder) => {
      builder.addCase(getRefunds.pending, (state) => {
        state.current_refunds_status = "loading"
      })
      builder.addCase(getRefunds.fulfilled, (state, action) => {
        state.current_refunds_status = "succeeded"
        state.data = action.payload?.data?.data
        state.total_rows = action.payload?.data?.meta?.total
        state.rows_per_page = action.payload?.data?.meta?.per_page
        state.current_page = action.payload?.data?.meta?.current_page
        state.last_page = action.payload?.data?.meta?.last_page
        state.suggestions = {
          seller: action.payload?.data?.meta?.suggestions
        }
      })
      builder.addCase(getRefunds.rejected, (state) => {
        state.current_refunds_status = "failed"
      })
      builder.addCase(getSellerRefunds.pending, (state) => {
        state.current_refunds_status = "loading"
      })
      builder.addCase(getSellerRefunds.fulfilled, (state, action) => {
        state.current_refunds_status = "succeeded"
        state.details = action.payload?.data?.data
        state.total_rows = action.payload?.data?.meta?.total
        state.rows_per_page = action.payload?.data?.meta?.per_page
        state.current_page = action.payload?.data?.meta?.current_page
        state.last_page = action.payload?.data?.meta?.last_page
        state.seller_name = action.payload?.data?.meta?.seller
        state.detailsResume = {
          total_refunds: action.payload?.data?.meta?.total_refunds,
          count_refunds: action.payload?.data?.meta?.count_refunds
        }
        state.detailsSuggestions = {
          invoice: action.payload?.data?.meta?.suggestions_folios,
          taxdata: action.payload?.data?.meta?.suggestions_taxdata
        }
      })
      builder.addCase(getSellerRefunds.rejected, (state) => {
        state.current_refunds_status = "failed"
      })
      builder.addCase(payRefunds.pending, (state) => {
        state.current_refunds_status = "loading"
      })
      builder.addCase(payRefunds.fulfilled, (state) => {
        state.current_refunds_status = "succeeded"
      })
      builder.addCase(payRefunds.rejected, (state) => {
        state.current_refunds_status = "failed"
      })
    }
})

export const {updateRange, setParams, setParamsDetails, setFilterSelected, setFilterDetailsSelected} = refundsSlice.actions

export default refundsSlice.reducer
// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

import { Check, ToastSuccess } from "../../../assets/images/icons"
import SuccessToast from "../../../components/common/toast/SuccessToast"
import ErrorToast from "../../../components/common/toast/ErrorToast"
import { toast } from "react-toastify"

export const getPayments = createAsyncThunk(
  "payments/get",
  async (params = {}, { getState }) => {
    const state = getState()
    const product = state.products.productSelected.value
    params.product = product
    const response = await api.get(`api/payments`, { params })
    return {
      data: response.data,
      params
    }
  }
)

export const getPaymentsSuggestions = createAsyncThunk(
  "payments/getSuggestions",
  async (params, { getState }) => {
    const state = getState()
    const product = state.products.productSelected.value
    params.product = product
    const response = await api.get(`api/payments/all/list`, { params })
    return {
      data: response.data
    }
  }
)

export const sendPaymentByEmail = createAsyncThunk(
  "payment/sendByEmail",
  async ({ id, params }) => {
    try {
      const response = await api.post(`api/payment_receipts/send_mail/${id}`, {
        ...params
      })
      toast(<SuccessToast message={response.data.message} />, {
        icon: <ToastSuccess />,
        hideProgressBar: true,
        className: "toast-success",
        autoClose: 5000
      })
      return {
        data: response.data,
        params
      }
    } catch (err) {
      toast(<ErrorToast message={"Error al enviar el correo"} />, {
        icon: null,
        hideProgressBar: true,
        className: "toast-danger",
        autoClose: 5000
      })
    }
  }
)

export const deletePayment = createAsyncThunk(
  "payments/delete",
  async ({ id }) => {
    const response = await api.delete(`api/payments/${id}`)
    return {
      data: response.data
    }
  }
)

export const paymentSlice = createSlice({
  name: "payments",
  initialState: {
    payments: [],
    data: null,
    params: {},
    autocompleteValues: null,
    message: "",
    response: null,
    preview_url: null,
    payment: null, // payment created
    paymentDeleted: null, // Payment deleted
    paymentUpdated: null,
    paymentPaid: null,
    payment_methods: [],
    error: null,
    loading: false,
    showPayment: {}
  },
  autocompleteValues: [],
  reducers: {
    clearPayments: (state) => {
      state.payments = [] // data from backend
      state.preview_url = null
      state.payment = null // payment created
      state.paymentDeleted = null
      state.paymentUpdated = null
      state.paymentPaid = null
      state.response = null
    },
    clearPaymentPaid: (state) => {
      state.paymentPaid = null
    },
    paymentSelected: (state, action) => {
      state.showPayment = action.payload.payment
      localStorage.setItem(
        "show_payment",
        JSON.stringify(action.payload.payment)
      )
    },
    getPaymentSelected: (state) => {
      const showPayment = JSON.parse(localStorage.getItem("show_payment"))
      state.showPayment = showPayment
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.params = action.payload.params
    })
    builder.addCase(getPayments.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPayments.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getPaymentsSuggestions.fulfilled, (state, action) => {
      state.autocompleteValues = action.payload.data
    })
    builder.addCase(deletePayment.fulfilled, (state, action) => {
      state.message = action.payload.data.message
    })
    builder.addCase(sendPaymentByEmail.fulfilled, (state, action) => {
      state.response = action.payload.data
    })
  }
})

export const {
  clearPayments,
  clearPaymentPaid,
  paymentSelected,
  getPaymentSelected
} = paymentSlice.actions
export default paymentSlice.reducer

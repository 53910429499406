import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "@src/api/config"

// Acción asincrónica para obtener las ultimas notificaciones sin leer
export const getUnReadCountNotifications = createAsyncThunk(
  "notifications/getUnReadCountNotifications",
  async () => {
      const { data } = await api.get(`api/notifications/unreadcount`)
      return data
  }
)

// Acción asincrónica para obtener todas las notificaciones
export const getNotifications = createAsyncThunk(
  "notifications/get",
  async (params, { getState, dispatch }) => {
    const state = getState()
    const pages = state.notifications.pages
    const page = params.page ?? 1
    const current_params = state.notifications.params
    const product = state.products.productSelected.value
    params.product = product
    if (
      Object.keys(pages).length === 0 ||
      !pages.hasOwnProperty(page) ||
      !_.isEqual(current_params, params) ||
      params.force
    ) {
      const response = await api.get(`api/notifications`, { params })
      console.log(response)
      const { ...auxParams } = params
      dispatch(getUnReadCountNotifications())
      return {
        params: auxParams,
        data: response.data
      }
    } else if (pages.hasOwnProperty(page)) {
      return {
        params,
        data: pages[page]
      }
    }
  }
)

// Acción asincrónica para obtener todas las ultimas notificaciones para toast
export const getLastNotifications = createAsyncThunk(
  "notifications/getLastNotifications",
  async () => {
      const { data } = await api.get(`api/lastNotifications`)
      return data
  }
)

export const getNotificationsByType = createAsyncThunk(
  "notifications/getByType",
  async (id, params = {}, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const response = await api.get(`api/notifications/getByType/${id}`, {
      params
    })
    return {
      params,
      data: response.data
    }
  }
)

// Acción asincrónica para marcar una notificación como leída
export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (notificationId, { rejectWithValue, dispatch }) => {
    try {
      const id = typeof notificationId === 'object' ? notificationId.notificationId : notificationId
      const response = await api.put(
        `/api/notifications/${id}/mark-as-read`
      )
      console.log(response)
      dispatch(getUnReadCountNotifications())
      return {
        data: response.data
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Acción asincrónica para obtener configuración del usuario
export const getConfigurationUser = createAsyncThunk(
  "notifications/getConfigurationUser",
  async (params, { getState, dispatch }) => {
    const state = getState()
    //const current_params = state.notifications.params
    const product = state.products.productSelected.value
    params.product = product
    const response = await api.get(`api/notifications/getConfigurationUser`, {
      params
    })
    dispatch(getNotifications({ page: 1 }))
    console.log(response)
    const { ...auxParams } = params
    return {
      params: auxParams,
      data: response.data
    }
  }
)

// Acción asincrónica para setear configuración del usuario
export const setSubtypeConfiguration = createAsyncThunk(
  "notifications/setSubtypeConfiguration",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/api/notifications/setSubtypeConfiguration/${params.item}/${params.value}`
      )
      console.log(response)
      const { ...auxParams } = params
      return {
        params: auxParams,
        data: response.data
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const initialState = {
  notifications: [], //data from backend
  last_notifications: [],
  unread_count: 0,
  pages: {},
  params: {},
  autocompleteValues: [],
  configUpdated: null,
  notificationUpdated: null
}

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationRead: (state, action) => {
      state.notificationSelected = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.error = null
    })
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      const { page: pageAux, ...current_params } = state.params
      const { page: newPageAux, ...new_params } = action.payload?.params
      state.notifications = action.payload?.data
      //state.notifications = action.payload?.data?.data
      // state.notifications = action.payload?.data?.data?.response
      state.params = action.payload.params
      const page = action.payload?.params?.page ?? 1
      console.log(pageAux)
      console.log(newPageAux)
      if (_.isEqual(current_params, new_params)) {
        state.pages[page] = action.payload?.data
      } else {
        state.pages = {}
        state.pages[page] = action.payload?.data
      }
      // console.log(pageAux)
      // console.log(newPageAux)
    })
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(getNotificationsByType.fulfilled, (state, action) => {
      state.notifications = action.payload?.data?.data?.response
    })
    builder.addCase(getUnReadCountNotifications.fulfilled, (state, action) => {
      state.unread_count = action.payload?.data
    })
    builder.addCase(getLastNotifications.fulfilled, (state, action) => {
      state.last_notifications = action.payload?.data
    })
    builder.addCase(markAsRead.pending, (state) => {
      state.error = null
    })
    builder.addCase(markAsRead.fulfilled, (state, action) => {
      // Actualiza las notificaciones marcadas como leídas
      //state.notificationUpdated = action.data.response
      state.notificationUpdated = action.payload.data
      // state.notifications.map((notification) => {
      //   if (notification.id === action.payload.id) {
      //     return {
      //       ...notification,
      //       read: true,
      //     }
      //   }
      //   return notification
      // })
      // Actualiza la cantidad de notificaciones sin leer
      //state.unreadCount -= 1
    })
    builder.addCase(markAsRead.rejected, (state, action) => {
      state.error = action.error.message
    })
    //ConfigurationUser
    builder.addCase(getConfigurationUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getConfigurationUser.fulfilled, (state, action) => {
      state.loading = false
      state.userconfig = action.payload?.data
      state.paramsuserconfig = action.payload?.params
    })
    builder.addCase(getConfigurationUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(setSubtypeConfiguration.fulfilled, (state, action) => {
      state.configUpdated = action.payload.data
    })
    builder.addCase(setSubtypeConfiguration.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { setNotificationsRead } = notificationSlice.actions
export default notificationSlice.reducer

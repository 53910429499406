// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
// import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
// import { hideModalCustom } from "../../../../redux/modalTypeCustom"

const urlBase = "api/project-profile"

const initialState = {
    data: [],
    suggestions: [],
    // defaultValues: {},
    currentPage: 1,
    params: {}, // all filters
    meta: {},
    total: 0,
    rowsPerPage: 10,
    loading: false,
    projectProfile: {},
    projectProfileOptions: [],
    projectProfileLimits: {},
    clientTypes: [],
    feeTypes: []
  }

  export const getFeeTypes = createAsyncThunk(
    "projectProfiles/getFeeTypes",
    async (params, {}) => {
      const response = await api.get(`api/fee-type`, { params })
      return {
        params,
        data: response.data
      }
    }
  )

export const getProjectProfiles = createAsyncThunk(
  "projectProfiles/get",
  async (params, {getState}) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const response = await api.get(`${urlBase}`, { params })
    
    return {
      params,
      data: response.data
    }
  }
)

export const getProjectProfileById = createAsyncThunk(
  "projectProfiles/getById",
  async (id) => {
    const response = await api.get(`api/project-profile/${id}`)
    return {
      data: response.data
    }
  }
)

export const getProjectProfileLimits = createAsyncThunk(
  "projectProfiles/getLimits",
  async (id) => {
    const response = await api.get(`api/project-profile/${id}/limits`)
    return {
      data: response.data
    }
  }
)

export const createProjectProfiles = createAsyncThunk("projectProfiles/store", async (payload = {}, {getState, dispatch}) => {
  const state = getState()
  const { productSelected } = state.products
  payload.product = productSelected?.value
  const response = await api.post(`${urlBase}`, payload)

  // success handler
  if (response.data.status === 'success') {
    if (payload.from === 'providers') dispatch(getProjectProfiles({ format: "options" }))
    dispatch(showModalSuccess(payload.from === 'providers' ? {message: response.data.message} : {message: response.data.message, url: '/catalogs/project_profile'}))
  }

  return {
    data: response.data
  }
})

export const getProjectProfilesSuggestion = createAsyncThunk(
  "projectProfiles/getSuggestion",
  async (params, {getState}) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const response = await api.get('api/project-profile/suggestions', { params })
    return {
      params,
      data: response.data
    }
  }
)

export const updateProjectProfile = createAsyncThunk(
  "projectProfiles/update",
  async ({id, payload}, {dispatch}) => {
    const response = await api.put(`api/project-profile/${id}`, payload)

    // success handler
    if (response.data.status === 'success') {
      if (payload.from === 'providers') dispatch(getProjectProfiles({ format: "options" }))
      dispatch(showModalSuccess(payload.from === 'providers' ? {message: response.data.message} : {message: response.data.message, url: '/catalogs/project_profile'}))
    }

    return {
      data: response.data
    }
  }
)

export const deleteProjectProfile = createAsyncThunk(
  "projectProfiles/destroy",
  async (id, {dispatch}) => {
    const response = await api.delete(`api/project-profile/${id}`)

    // success handler
    if (response.data.status === 'success') {
      dispatch(showModalSuccess({message: response.data.message}))
      dispatch(getProjectProfiles({page: 1}))
    }

    return {
      data: response.data
    }
  }
)

export const getClientTypes = createAsyncThunk(
  "projectProfiles/getClientTypes",
  async (params, {}) => {
    const response = await api.get(`api/client-types`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const projectProfilesSlice = createSlice({
  name: "projectProfiles",
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectProfiles.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProjectProfiles.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getProjectProfiles.fulfilled, (state, action) => {
      state.loading = false
      if (action.payload?.params?.format === 'options') {
        state.projectProfileOptions = action.payload.data
      } else {
        state.data = action.payload.data.data
        state.currentPage = action.payload.data.meta.current_page
        state.params = action.payload.params
        state.meta = action.payload.data.meta
        state.total = action.payload.data.meta.total
        state.rowsPerPage = action.payload.data.meta.per_page
      }
    })
    builder.addCase(getProjectProfilesSuggestion.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
    builder.addCase(getFeeTypes.fulfilled, (state, action) => {
      state.feeTypes = action.payload.data
    })
    builder.addCase(getProjectProfileById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProjectProfileById.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getProjectProfileById.fulfilled, (state, action) => {
      state.loading = false
      state.projectProfile = action.payload.data.data
    })
    builder.addCase(getProjectProfileLimits.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProjectProfileLimits.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getProjectProfileLimits.fulfilled, (state, action) => {
      state.loading = false
      state.projectProfileLimits = action.payload.data
    })
    builder.addCase(getClientTypes.fulfilled, (state, action) => {
      state.clientTypes = action.payload.data
    })
  }
})


export default projectProfilesSlice.reducer

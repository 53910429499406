// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import axios from "axios"
import { Fragment } from "react"
import { showModalSuccess } from "@src/redux/modalTypeSuccess"
// import { showModalDelete } from "../../../redux/modalTypeDelete"
// import axios from "axios"
// import { toast } from 'react-toastify'

import fileDownload from 'js-file-download'
// Toast
import { ToastSuccess } from "../../../assets/images/icons"
import SuccessToast from "../../../components/common/toast/SuccessToast"
import ErrorToast from "../../../components/common/toast/ErrorToast"
import { toast } from 'react-toastify'
import { showModalDelete } from "../../../redux/modalTypeDelete"

export const getDownloadRep = createAsyncThunk("download/rep", async (id) => {
  // console.debug("getDownloadRep", id)
  const response = await api.get(`api/payment_receipts/download/${id}`, { responseType: 'blob' })
    .then((res) => {
      fileDownload(res.data, `recibo_de_pago_${id}.pdf`)
    })
  return {
    data: response.data
  }
})

export const getPreviewRep = createAsyncThunk("preview/rep", async (id) => {
  const response = await api.get(`api/payment_receipts/file/${id}`)
  return {
    data: response.data
  }
})

export const getPreviewRepByOpenpayId = createAsyncThunk("preview/rep/openpay", async (openpayId) => {
  const response = await api.get(`api/payment_receipts/payment_openpay/${openpayId}`)
  // console.debug("message response", response)
  try {
    if (response?.data?.data?.url) {
      toast(<SuccessToast message={"Factura pagada con éxito"} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    } else {
      toast(<ErrorToast message={response?.data?.message ?? "No fue posible recuperar el recibo de pago"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    }
    return {
      data: response.data
    }
  } catch (err) {
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible recuperar el recibo de pago"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible recuperar el recibo de pago")
  }
})


export const createChargeWithCard = createAsyncThunk("charges/card", async (payload) => {
  const response = await api.post(`api/charges/card`, payload)
  // console.debug("response: ", response)
  // show success message
  if (response.data) {
    const url = response.data.redirect_url
    // console.debug('redirect to: ', url)
    location.href = url
    // toast(<SuccessToast />, { icon: <ToastSuccess/>, hideProgressBar: true, className: "toast-success", autoClose: 5000})
    // localStorage.removeItem('selectedInvoices') // clear selected certs from storage
    // download REP, and show file preview
    // dispatch(getPreviewRep(response.data.data.id))
  }
  return {
    data: response.data
  }
})

export const getTransferAccount = createAsyncThunk("transfer/card", async (payload) => {
  const response = await api.get(`api/transfer_account`, payload)
  return {
    data: response.data
  }
})

export const downloadTransferHandlerPDF = createAsyncThunk("transfer/download", async () => {
  axios.get(`download/pdf/bank-transfer`)
    .then(blob => {
      fileDownload(blob, "transferencia_bancaria.pdf")
    })

})

export const createBankTransfer = createAsyncThunk("transfer/bank", async (payload, { getState }) => {
  const state = getState()
  payload.product = state.products.productSelected.value
  const response = await api.post(`api/bank_transfer`, payload)
  return {
    data: response.data
  }
})

export const createBankTransferStripe = createAsyncThunk("transfer/bankStripe", async (payload, { getState }) => {
  const state = getState()
  payload.product = state.products.productSelected.value
  const response = await api.post(`api/bank_transfer/stripe`, payload)
  return {
    data: response.data
  }
})

export const createUsdTransfer = createAsyncThunk("transfer/usd", async (payload, { getState }) => {
  const state = getState()
  payload.product = state.products.productSelected.value
  const response = await api.post(`api/manual_payment`, payload)
  return {
    data: response.data
  }
})

export const createMxnTransfer = createAsyncThunk("transfer/mxn", async (payload, { getState }) => {
  const state = getState()
  payload.product = state.products.productSelected.value
  const response = await api.post(`api/manual_payment`, payload)
  return {
    data: response.data
  }
})

export const createStoreCharge = createAsyncThunk("store/charge", async (payload) => {
  try {
    const response = await api.post(`api/store_charge`, payload)
    // console.log("response from backend store charge: ", response.data)
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err)
  }
})

export const uploadVoucher = createAsyncThunk(
  "paymentReferences/uploadVoucher",
  async ({ id, params, stepper }, { dispatch }) => {
    try {
      const response = await api.post(`api/voucher/upload/${id}`, {
        ...params
      })
      // setUploading(false)
      // setLocalLoading(false)
      if (response.data.status === 'success') {
        dispatch(showModalSuccess({
          message: response.data.message,
          action: stepper,
          url: null
        }))
      }
      return {
        data: response.data,
        params
      }
    } catch (err) {
      console.error("Error uploadVoucher", err)
      toast(<ErrorToast message={"Error cargar los archivos"} />, { icon: null, hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    }
  }
)

export const getVouchersUrl = createAsyncThunk("vouchers/show", async ({ id }) => {
  try {
    const response = await api.get(`api/show_vouchers/${id}`)
    // console.log("response from backend store charge: ", response.data)
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err)
  }
})

export const getPayment = createAsyncThunk("payment/getById", async ({ id }) => {
  try {
    const response = await api.get(`api/payments/${id}`)
    // console.log("response from backend store charge: ", response.data)
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err)
  }
})

export const approveManualPayment = createAsyncThunk("payment/approve", async ({ id, params }, { dispatch }) => {
  try {
    const response = await api.post(`api/manual_payment/approve/${id}`, params)
    // console.debug("response status on approve", response.data.status)
    if (response.data.status === 'success') {
      if (response.data.data.invoices[0]?.uncollectible_at) {
        dispatch(showModalSuccess({ message: response.data.message, url: `/payments` }))
      } else {
        const payment_id = response.data.data.receipt.id
        dispatch(showModalSuccess({ message: response.data.message, url: `/payments/show/${payment_id}` }))
      }
    } else {
      dispatch(showModalDelete({ message: response.data.message, autoClose: true }))
    }
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err)
    const message = err.response.data?.message ?? "No fue posible conciliar la factura"
    dispatch(showModalDelete({ message, autoClose: true }))
    new Error(message ?? "No fue posible conciliar la factura")
  }
})

export const rejectManualPayment = createAsyncThunk("payment/reject", async ({ id, params }, { dispatch }) => {
  try {
    const response = await api.post(`api/manual_payment/reject/${id}`, params)
    if (response.data.status === 'success') {
      dispatch(showModalSuccess({ message: response.data.message, url: `/payments` }))
    }
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err)
  }
})

export const paymentReferencesSlice = createSlice({
  name: "paymentReferences",
  initialState: {
    successResponse: false,
    stateLoading: false,
    certificateSelected: [],
    paymentResponse: [],
    transferBankAccount: false,
    transferUsd: false,
    transferMxn: false,
    storeCharge: null,
    invoicesSelected: [],
    preview_url: '',
    payment: null,
    paymentSelected: null,
    vouchers: []
  },
  reducers: {
    clearPaymentReferences: (state) => {
      // console.debug("clearPaymentReferences")
      state.successResponse = false
      state.stateLoading = false
      state.certificateSelected = []
      state.paymentResponse = []
      state.transferBankAccount = false
      state.transferUsd = false
      state.transferMxn = false
      // state.storeCharge =  null
      state.invoicesSelected = []
      state.preview_url = ''
      state.payment = null
      state.paymentSelected = null
    },
    clearPayments: (state) => {
      // console.debug("clearPayments")
      state.payment = null
      state.successResponse = false
      state.stateLoading = false
      state.transferBankAccount = false
      state.transferUsd = false
      state.transferMxn = false
      state.storeCharge = null
    },
    addItems: (state, action) => {
      localStorage.setItem("selectedRows", JSON.stringify(action.payload.selectedRows))
      state.certificateSelected = action.payload.selectedRows
    },
    removeItem: (state, action) => {
      const tmp = state.certificateSelected
      if (tmp.length > 1) {
        for (let i = 0; i < state.certificateSelected.length; i++) {
          if (i === action.payload.index) {
            tmp.splice(i, 1)
          }
        }
        state.certificateSelected = tmp
      } else {
        state.certificateSelected = []
      }
    },
    startLoading: state => { state.stateLoading = true },
    stopLoading: state => { state.stateLoading = false },
    addInvoiceItem: (state, action) => {
      localStorage.setItem("selectedInvoices", JSON.stringify(action.payload.selectedInvoices))
      state.invoicesSelected = action.payload.selectedInvoices
    },
    removeInvoiceItem: (state, action) => {
      const tmp = state.invoicesSelected
      if (tmp.length > 1) {
        for (let i = 0; i < state.invoicesSelected.length; i++) {
          if (i === action.payload.index) {
            tmp.splice(i, 1)
          }
        }
        state.invoicesSelected = tmp
      } else {
        state.invoicesSelected = []
      }
    },
    setPaymentSelected: (state, action) => {
      state.paymentSelected = action.payload.payment
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createChargeWithCard.fulfilled, (state, action) => {
      // console.debug("createChargeWithCard", action)
      if (action.payload?.data) {
        state.paymentResponse = action.payload.data
        state.successResponse = true
        // state.preview_url = action.payload.data.data.id
      }
      state.stateLoading = false
    })
    builder.addCase(createBankTransfer.fulfilled, (state, action) => {
      // console.debug("getTransferAccount", action)
      state.transferBankAccount = {
        ...action.payload.data.bank_transfer,
        require_payment: true
      }
      state.payment = action.payload.data.payment
    })
    builder.addCase(createBankTransferStripe.fulfilled, (state, action) => {
      // console.debug("getTransferAccountStripe", action)
      state.transferBankAccount = {
        ...action.payload.data.bank_transfer,
        require_payment: action.payload.data.require_payment
      }
      state.payment = action.payload.data.payment
    })
    builder.addCase(createStoreCharge.fulfilled, (state, action) => {
      // console.debug("createStoreCharge", action)
      state.storeCharge = action.payload.data.store_charge
      state.payment = action.payload.data.payment
    })
    builder.addCase(createUsdTransfer.fulfilled, (state, action) => {
      // console.debug("createUsdTransfer", action)
      state.transferUsd = true
      state.payment = action.payload.data.data
    })
    builder.addCase(createMxnTransfer.fulfilled, (state, action) => {
      // console.debug("createMxnTransfer", action)
      state.transferMxn = true
      state.payment = action.payload.data.data
    })
    builder.addCase(getPreviewRep.fulfilled, (state, action) => {
      // console.debug("getPreviewRep", action.payload)
      state.preview_url = action.payload.data
    })
    builder.addCase(getPreviewRepByOpenpayId.fulfilled, (state, action) => {
      // console.debug("getPreviewRepByOpenpayId", action.payload)
      state.preview_url = action.payload.data.data.url
      state.paymentResponse = action.payload.data.data.payment
    })
    builder.addCase(uploadVoucher.fulfilled, (state, action) => {
      console.debug("uploadVoucher", action)
      state.paymentSelected = action.payload.data.data
    })
    builder.addCase(uploadVoucher.rejected, (state, action) => {
      console.debug("uploadVoucher rejected", action)
    })
    builder.addCase(getVouchersUrl.fulfilled, (state, action) => {
      // console.debug("getVouchersUrl", action.payload.data)
      state.vouchers = action.payload.data
    })
    builder.addCase(getPayment.fulfilled, (state, action) => {
      // console.debug("getPayment", action.payload.data)
      state.paymentSelected = action.payload.data.data
    })
    builder.addCase(approveManualPayment.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        state.paymentSelected = action.payload.data.data
      }

      state.stateLoading = false
    })
  }
})

export const {
  removeItem,
  startLoading,
  stopLoading,
  addItems,
  removeInvoiceItem,
  addInvoiceItem,
  clearPaymentReferences,
  clearPayments,
  setPaymentSelected
} = paymentReferencesSlice.actions

export default paymentReferencesSlice.reducer

// ** Redux Imports
// path: @src/views/Catalogs/Concepts/store
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
// ** Axios Imports
import api from "@src/api/config"

/**
 * Retrieve coverage concepts catalog, only concepts already created
 */
export const getConcepts = createAsyncThunk(
  "getConcepts",
  async (params = {}, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const response = await api.get(`api/concepts`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const getSuggestions = createAsyncThunk(
  "getSuggestions",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected.value
    const response = await api.get(`api/concepts/suggestions`, { params })
    return {
      data: response.data
    }
  }
)

/** After select a concept id retrieve all providers belongs to ensured good */
export const getProvidersByConcept = createAsyncThunk(
  "concetps/getProviders",
  async (id) => {
    const response = await api.get(`api/concept/providers/${id}`)
    return {
      data: response.data
    }
  }
)

export const deleteConcept = createAsyncThunk(
  "concepts/delete",
  async (id, { dispatch }) => {
    const response = await api.delete(`api/concepts/${id}`)
    if (response.data.status === "success") {
      dispatch(showModalSuccess({ message: response.data.message }))
      await dispatch(getConcepts())
    }
    return {
      data: response.data
    }
  }
)

export const createConcept = createAsyncThunk(
  "concepts/create",
  async (data = {}, { dispatch, getState }) => {
    const state = getState()
    const { productSelected } = state.products
    data.producto = productSelected?.value
    const response = await api.post("api/concepts", data)
    if (response.data.status === "success") {
      if (data.from === "providers") {
        dispatch(getConcepts({ format: "options", free: true }))
      }
      await dispatch(
        showModalSuccess(
          data.from === "providers"
            ? { message: response.data.message }
            : { message: response.data.message, url: "goBack" }
        )
      )
    }
    return {
      data: response.data
    }
  }
)

export const updateConcept = createAsyncThunk(
  "concepts/update",
  async ({ id, data }, { dispatch }) => {
    const response = await api.put(`api/concepts/${id}`, data)
    if (response.data.status === "success") {
      const url = "goBack"
      dispatch(showModalSuccess({ message: response.data.message, url }))
    }
    return {
      data: response.data
    }
  }
)

export const getConceptById = createAsyncThunk(
  "concepts/getById",
  async (id) => {
    const response = await api.get(`api/concepts/${id}`)
    return {
      data: response.data
    }
  }
)

export const conceptsSlice = createSlice({
  name: "concepts",
  initialState: {
    data: [],
    suggestions: [],
    concepts: [],
    providers: [],
    defaultValues: null,
    currentPage: 1,
    params: {},
    meta: {},
    total: 0
  },
  reducers: {
    resetConcepts: (state) => {
      state.data = []
      state.concepts = []
      state.defaultValues = null
      state.currentPage = 1
      state.params = {}
      state.meta = {}
      state.total = 0
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getConcepts.fulfilled, (state, action) => {
      if (action.payload?.params?.format === "options") {
        state.concepts = action.payload.data
      } else {
        state.data = action.payload.data
        state.currentPage = action.payload.data.current_page
        state.params = action.payload.params
        state.meta = action.payload.data.meta
        state.total = action.payload.data.meta.total
      }
    })
    builder.addCase(getConceptById.fulfilled, (state, action) => {
      state.defaultValues = {
        concepto: action.payload.data.data.concept
      }
    })
    builder.addCase(getProvidersByConcept.fulfilled, (state, action) => {
      state.providers = action.payload.data
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
  }
})

export const { resetConcepts } = conceptsSlice.actions

export default conceptsSlice.reducer

// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const modalTypeEdit = createSlice({
  name: 'modals_edit',
  initialState: {
    toggle: false,
    message: "",
    action: null
  },
  reducers: {
    showModalEdit: (state, action) => {
      state.toggle = true
      state.message = action.payload.message
      state.action = action.payload.action
    },
    hideModalEdit: (state) => {
      state.toggle = false
      state.message = ""
      state.action = null
    }
  }
})

export const { showModalEdit, hideModalEdit } = modalTypeEdit.actions

export default modalTypeEdit.reducer

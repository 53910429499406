// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { showModalSuccess } from "../../../redux/modalTypeSuccess"
import { hideModalCustom } from "../../../redux/modalTypeCustom"
import fileDownload from 'js-file-download'

// ** Axios Imports
import api from "@src/api/config"
import { getHistory as getHistoryData } from "./history"
import { getSellerName } from "../../Users/Sellers/store"
// import { now } from "../../../configs/datetime"

export const getCommisions = createAsyncThunk(
  "commissions/get",
  async (params) => {
    const response = await api.get(`api/payable_commissions`, { params })
    return {
      data: response.data,
      params
    }
  }
)

export const getSuggestions = createAsyncThunk(
  "commissions/suggestions",
  async (params) => {
    const response = await api.get(`api/payable_commissions/all/list`, {
      params
    })
    return {
      data: response.data,
      params
    }
  }
)

export const getHistorySuggestions = createAsyncThunk(
  "commissions/history/suggestions",
  async (params) => {
    const response = await api.get(`api/commissions/history/suggestions`, {
      params
    })
    return {
      data: response.data,
      params
    }
  }
)

export const getPendingComissionsDetailsSuggestions = createAsyncThunk(
  "commissions/pending/suggestions",
  async ({ user, params }) => {
    const response = await api.get(`api/payable_commissions/${user}/list`, {
      params: {
        ...params,
        cutoff
      }
    })
    return {
      data: response.data
    }
  }
)

export const getCommissionDetailsSuggestions = createAsyncThunk(
  "commissions/commission/suggestions",
  async ({ commission }) => {
    const response = await api.get(
      `api/commissions/history/${commission}/suggestions`
    )
    return {
      data: response.data
    }
  }
)

export const getSellerDetail = createAsyncThunk(
  "commissions/getSellerDetail",
  async ({ user_id, cutoff, params }) => {
    const response = await api.get(`api/payable_commissions/${user_id}`, {
      params: {
        ...params,
        cutoff
      }
    })
    return {
      data: response.data,
      params
    }
  }
)

export const getHistory = createAsyncThunk(
  "commissions/getHistory",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    params.product = productSelected?.value
    const response = await api.get(`api/commissions/history`, { params })
    return {
      data: response.data,
      params
    }
  }
)

//Returns detailed history of a commission
export const getDetailHistory = createAsyncThunk(
  "commissions/getDetailHistory",
  async ({ commission, params }, {dispatch}) => {
    const response = await api.get(`api/commissions/history/${commission}`, {
      params
    })
    if (response.data) { // At this point we know the user id, retrieve full name from next endpoint
      const id = response.data.data[0].user_id
      dispatch(getSellerName({id}))
    }

    return {
      data: response.data,
      params
    }
  }
)

export const getCommissionsReport = createAsyncThunk(
  'commissionsReport/create',
  async ({ params, filename }) => {
    try {
      const response = await api.post(`api/commissions/export`, params, { responseType: 'blob' })
      fileDownload(response.data, filename)
      return {
        data: response.data,
        params
      }
    } catch (err) {
      // console.error(err)
    }
  }
)

export const postCommissions = createAsyncThunk(
  "commissions/pay",
  async ({ params, toggle, setClearRows }, { dispatch, getState }) => {
    const response = await api.post(`api/commissions/pay`, params)
    try {
      const state = getState()
      const {productSelected} = state.products
      dispatch(hideModalCustom())
      if (response.data.status === "success") {
        const url = `/accountants/commissions`
        dispatch(showModalSuccess({ message: response.data.message, url }))
        toggle()
        setClearRows(true)
        dispatch(getCommisions({ page: 1, product: productSelected?.value }))
        dispatch(getHistoryData({ page: 1, product: productSelected?.value }))
      }
    } catch (err) {
      dispatch(hideModalCustom())
    }
  }
)

export const getInvoiceCertificates = createAsyncThunk(
  "commissions/getInvoiceCertificates",
  async (invoice) => {
    const response = await api.get(`api/invoice/certificates/${invoice}`)
    return {
      data: response.data
    }
  }
)

export const commissionsSlice = createSlice({
  name: "commissions",
  initialState: {
    data: [],
    comissionDetails: [],
    comissionsDetailsSuggestions: {
      invoice: [],
      taxdata: []
    },
    comissionsDetailsResume: null,
    history: [],
    historyDetails: [],
    autocompleteValues: [],
    params: {
      page: 1, // current page
      orderBy: 'folio',
      sort: 'desc',
      range: null,
      // folio: null,
      // tab: 'current_balance',
      // // filters section
      only_mine: false,
      contract: '',
      seller: null,
      filter: 'cutOff'
    },
    filters: 0,
    commissions: [],
    commissions_suggestions: [],
    suggestions: [],
    historySuggestions: [],
    detailsSuggestions: [],
    historyDetailsSuggestions: [],
    invoiceCertificates: [],
    seller_name: "",
    // details: null,
    startDate: null,
    endDate: null,
    search_key: 'seller',
    search_label: 'Vendedor',
    search_key_details: 'invoice',
    search_label_details: 'Factura',
    current_commissions_status: "idle",
    rows_per_page: 10,
    total_rows: 1,
    current_page: 1,
    last_page: 1
  },
  reducers: {
    updateRange: (state, action) => {
      state.startDate = action.payload.range[0]
      state.endDate = action.payload.range[1]
    },
    setParams: (state, action) => {
      state.params = action.payload
    },
    setFilterSelected: (state, action) => {
      state.search_key = action.payload?.key
      state.search_label = action.payload?.label
    },
    setFilterDetailsSelected: (state, action) => {
      state.search_key_details = action.payload?.key
      state.search_label_details = action.payload?.label
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCommisions.pending, (state) => {
      state.current_commissions_status = "loading"
    })
    builder.addCase(getCommisions.fulfilled, (state, action) => {
      state.current_commissions_status = "succeeded"
      state.commissions = action.payload?.data?.data
      state.total_rows = action.payload?.data?.meta?.total
      state.rows_per_page = action.payload?.data?.meta?.per_page
      state.current_page = action.payload?.data?.meta?.current_page
      state.last_page = action.payload?.data?.meta?.last_page
      state.commissions_suggestions = action.payload?.data?.meta?.suggestions
      let count = 0
      if (action.payload?.params?.range) count++
      if (action.payload?.params?.only_mine === 'true' || action.payload?.params?.only_mine === true) count++
      state.filters = count
    })
    builder.addCase(getCommisions.rejected, (state) => {
      state.current_commissions_status = "failed"
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
    builder.addCase(getSellerDetail.pending, (state) => {
      state.current_commissions_status = "loading"
    })
    builder.addCase(getSellerDetail.fulfilled, (state, action) => {
      state.current_commissions_status = "succeeded"
      state.comissionDetails = action.payload?.data?.data
      state.total_rows = action.payload?.data?.meta?.total
      state.rows_per_page = action.payload?.data?.meta?.per_page
      state.current_page = action.payload?.data?.meta?.current_page
      state.last_page = action.payload?.data?.meta?.last_page
      state.seller_name = action.payload?.data?.meta?.seller
      state.comissionsDetailsResume = {
        total_commissions: action.payload?.data?.meta?.total_commissions,
        count_commissions: action.payload?.data?.meta?.count_commissions,
        total_retentions: action.payload?.data?.meta?.total_retentions,
        count_retentions: action.payload?.data?.meta?.count_retentions,
        cutoff: action.payload?.data?.meta?.cutoff
      }
      state.comissionsDetailsSuggestions = {
        invoice: action.payload?.data?.meta?.suggestions_folios,
        taxdata: action.payload?.data?.meta?.suggestions_taxdata
      }
    })
    builder.addCase(getSellerDetail.rejected, (state) => {
      state.current_commissions_status = "failed"
    })
    builder.addCase(getDetailHistory.fulfilled, (state, action) => {
      state.historyDetails = action.payload.data
      if (action.payload.data.data !== undefined && action.payload.data.data[0]?.seller_name !== undefined) {
        state.seller_name = action.payload.data.data[0]?.seller_name
      }
    })
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.history = action.payload.data
    })
    builder.addCase(getHistorySuggestions.fulfilled, (state, action) => {
      state.historySuggestions = action.payload.data
    })
    builder.addCase(
      getPendingComissionsDetailsSuggestions.fulfilled,
      (state, action) => {
        state.detailsSuggestions = action.payload.data
      }
    )
    builder.addCase(
      getCommissionDetailsSuggestions.fulfilled,
      (state, action) => {
        state.historyDetailsSuggestions = action.payload.data
      }
    )
    builder.addCase(getInvoiceCertificates.fulfilled, (state, action) => {
      state.invoiceCertificates = action.payload.data
    })
    builder.addCase(postCommissions.pending, (state) => {
      state.current_commissions_status = 'loading'
    })
    builder.addCase(postCommissions.fulfilled, (state) => {
      // clear range of dates
      state.current_commissions_status = "succeeded"
      state.startDate = null
      state.endDate = null
    })
    builder.addCase(postCommissions.rejected, (state) => {
      state.current_commissions_status = 'failed'
    })
  }
})

export const {updateRange, setParams, setFilterSelected, setFilterDetailsSelected} = commissionsSlice.actions

export default commissionsSlice.reducer

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getHistory = createAsyncThunk(
    "history/get",
    async (params, { getState }) => {
      const state = getState()
      const { productSelected } = state.products
      params =  { ...params, product: productSelected?.value }
      const response = await api.get(`api/commissions/history`, { params })
      return {
        data: response.data,
        params
      }
    }
)

  
export const getHistoryDetails = createAsyncThunk(
    "history/details",
    async ({ commission_id, params }) => {
      const response = await api.get(`api/commissions/history/${commission_id}`, { params })
      return {
        data: response.data,
        params
      }
    }
)

export const historyCommissionsSlice = createSlice({
    name: "historyCommissions",
    initialState: {
        data: [],
        suggestions: {
          folio: [],
          seller: []
        },
        details: [],
        detailsSuggestions: {
          invoice: [],
          taxdata: []
        },
        historyDetailsResume: null,
        params: {
          page: 1, // current page
          orderBy: 'folio',
          sort: 'desc',
          range: null,
          cutoff: null
        },
        paramsDetails: {
          page: 1,
          orderBy: 'folio',
          sort: 'desc',
          invoice: null,
          taxdata: null
        },
        filters: 0,
        seller_name: "",
        cutoff: "",
        startDate: null,
        endDate: null,
        search_key: 'folio',
        search_label: 'Pago',
        search_key_details: 'invoice',
        search_label_details: 'Factura',
        current_history_status: "idle",
        rows_per_page: 10,
        total_rows: 1,
        current_page: 1,
        last_page: 1
    },
    reducers: {
        updateRange: (state, action) => {
          // console.debug("updateRange", action.payload)
          state.startDate = action.payload.range[0]
          state.endDate = action.payload.range[1]
        },
        setParams: (state, action) => {
          state.params = action.payload
        },
        setParamsDetails: (state, action) => {
          // console.log('Payload:', action.payload)
          state.paramsDetails = action.payload
        },
        setFilterSelected: (state, action) => {
          state.search_key = action.payload?.key
          state.search_label = action.payload?.label
        },
        setDetailsFilterSelected: (state, action) => {
          state.search_key_details = action.payload?.key
          state.search_label_details = action.payload?.label
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getHistory.pending, (state) => {
          state.current_refunds_status = "loading"
        })
        builder.addCase(getHistory.fulfilled, (state, action) => {
          state.current_history_status = "succeeded"
          state.data = action.payload?.data?.data
          state.total_rows = action.payload?.data?.meta?.total
          state.rows_per_page = action.payload?.data?.meta?.per_page
          state.current_page = action.payload?.data?.meta?.current_page
          state.last_page = action.payload?.data?.meta?.last_page
          state.suggestions = {
            folio: action.payload?.data?.meta?.folios_suggestions,
            seller: action.payload?.data?.meta?.sellers_suggestions
          }
          let count = 0
          if (action.payload?.params?.range) count++
          if (action.payload?.params?.cutoff) count++
          state.filters = count
        })
        builder.addCase(getHistory.rejected, (state) => {
          state.current_history_status = "failed"
        })
        builder.addCase(getHistoryDetails.pending, (state) => {
          state.current_history_status = "loading"
        })
        builder.addCase(getHistoryDetails.fulfilled, (state, action) => {
          state.current_history_status = "succeeded"
          state.details = action.payload?.data?.data
          state.total_rows = action.payload?.data?.meta?.total
          state.rows_per_page = action.payload?.data?.meta?.per_page
          state.current_page = action.payload?.data?.meta?.current_page
          state.last_page = action.payload?.data?.meta?.last_page
          state.seller_name = action.payload?.data?.meta?.seller
          state.cutoff = action.payload?.data?.meta?.cutoff
          state.detailsSuggestions = {
            invoice: action.payload?.data?.meta?.suggestions_folios,
            taxdata: action.payload?.data?.meta?.suggestions_taxdata
          }
          state.historyDetailsResume = {
            sum_commissions: action.payload?.data?.meta?.sum_commissions,
            total_commissions: action.payload?.data?.meta?.total_commissions,
            sum_retentions: action.payload?.data?.meta?.sum_retentions,
            total_retentions: action.payload?.data?.meta?.total_retentions,
            sum_refunds: action.payload?.data?.meta?.sum_refunds,
            total_refunds: action.payload?.data?.meta?.total_refunds
          }
        })
        builder.addCase(getHistoryDetails.rejected, (state) => {
          state.current_history_status = "failed"
        })
    }
})

export const {updateRange, setParams, setParamsDetails, setFilterSelected, setDetailsFilterSelected} = historyCommissionsSlice.actions

export default historyCommissionsSlice.reducer
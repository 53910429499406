import './ReactotronConfig'
// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'

// ** Intl & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'
import { LoadingShield } from './components/common/LoadingShield'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
// import * as serviceWorker from './serviceWorker'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import ErrorBoundary from './ErrorBoundary.tsx'
import { persistStore } from 'redux-persist'

const persistor = persistStore(store)

ReactDOM.render(
  <ErrorBoundary>
    <ClearBrowserCacheBoundary auto fallback=' ' duration={60000}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<LoadingShield />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
          </ThemeContext>
        </AbilityContext.Provider>
        </Suspense>
        </PersistGate>
      </Provider>
    </ClearBrowserCacheBoundary>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

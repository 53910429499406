// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "../../../api/config"

export const getSales = createAsyncThunk("dashboard/sales", async (params = {}, {getState}) => {
  const state = getState()
  const product = state.products?.productSelected?.value
  params.product = product
  const response = await api.get(`api/dashboard/sales`, {params})
  return {data: response.data.data, params}
})

export const getCertificates = createAsyncThunk("dashboard/certificates", async (params = {}, {getState}) => {
  const state = getState()
  const product = state.products?.productSelected?.value
  params.product = product
  const response = await api.get(`api/dashboard/certificates`, {params})
  return {data: response.data, params}
})

export const getBusinessClients = createAsyncThunk("dashboard/tax_data", async (params) => {
  const response = await api.get(`api/dashboard/tax_data`, {params})
  return {data: response.data, params}
})

export const getCashing = createAsyncThunk("dashboard/cashing", async (params = {}, {getState}) => {
  const state = getState()
  const {productSelected} = state.products
  params.product = productSelected?.value

  const response = await api.get(`api/dashboard/cashing`, {params})
  return {data: response.data, params}
})
export const getCertificatesClient = createAsyncThunk("dashboard/certificates-client", async (params = {}, {getState}) => {
  const state = getState()
  const product = state.products?.productSelected?.value
  params.product = product
  const response = await api.get(`api/dashboard/certificates-client`, {params})
  return {data: response.data, params}
})

export const getSalesPerSeller = createAsyncThunk("dashboard/sales/seller", async (params = {}, {getState}) => {
  const state = getState()
  const product = state.products?.productSelected?.value
  params.product = product
  const response = await api.get(`api/dashboard/sales/seller`, {params})
  return {data: response.data, params}
})
export const getInvoices = createAsyncThunk("dashboard/invoices", async (params = {}, {getState}) => {
  const state = getState()
  const {productSelected} = state.products
  params.product = productSelected?.value

  const response = await api.get(`api/dashboard/invoices`, {params})
  return {data: response.data, params}
})
export const getExchangeRates = createAsyncThunk("dashboard/exchange-rate", async (params) => {
  const response = await api.get(`api/dashboard/exchange-rate`, {params})
  return {data: response.data, params}
})
export const getPayments = createAsyncThunk("dashboard/payments", async (params = {}, {getState}) => {
  const state = getState()
  const {productSelected} = state.products
  params.product = productSelected?.value
  const response = await api.get(`api/dashboard/payments`, {params})
  return {data: response.data, params}
})
export const getCommisions = createAsyncThunk("dashboard/comissions", async (params = {}, {getState}) => {
  const state = getState()
  const {productSelected} = state.products
  params.product = productSelected?.value
  
  const response = await api.get(`api/dashboard/comissions`, {params})
  return {data: response.data, params}
})
export const getClientsBySellerDash = createAsyncThunk("dashboard/clients/seller", async (params = {}, {getState}) => {
  const state = getState()
  const {productSelected} = state.products
  params.product = productSelected?.value

  const response = await api.get(`api/dashboard/clients/seller`, {params})
  return {data: response.data, params}
})
export const getClientsAdmin = createAsyncThunk("dashboard/clients/admin", async (params = {}, {getState}) => {
  const state = getState()
  const product = state.products?.productSelected?.value
  params.product = product
  const response = await api.get(`api/dashboard/clients/admin`, {params})
  return {data: response.data, params}
})

export const DashboardFiltersSlice = createSlice({
  name: "dashboard-filters",
  initialState: {
    searchOption: "",
    filterOption: "yearly",
    customDate: null,
    search: "",
    totalSales: 0,
    sales: {
      labels: [],
      datasets: [
          {
              data: [],
              label: 'Suma Asegurada',
              borderColor: "#0D6EFD",
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: "#0D6EFD",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#fff',
              pointHoverBackgroundColor: "#0D6EFD",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5
          },
          {
              data: [],
              label: 'Prima neta',
              borderColor: "#00CFE8",
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: "#00CFE8",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#fff',
              pointHoverBackgroundColor: "#00CFE8",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5
          }
      ]
    },
    invoices:[],
    exchange_rate:[],
    payments:{},
    clientsBySellerDash:{},
    clientsAdmin:{},
    comissions:{},
    certificates: [],
    totalCertificates: 0,
    businessClients: [],
    cashing: null,
    certificatesClient: null,
    totalBusinessClients: 0,
    salesPerSeller: {
      labels: [],
      amounts: []
    },
    berkleyMxn: 0,
    berkleyUsd: 0,
    zurichMxn: 0,
    zurichUsd: 0,
    salesAvg: 0,
    netPremiumAvg: 0,
    totalNetPremium: 0,
    salesLoading: false,
    certificatesLoading: false,
    clientsAdminLoading: false,
    salesPerSellerLoading: false,
    cashingLoading: true,
    clientsBySellerDashLoading: true,
    certificatesClientLoading: false,
    businessClientsLoading: false,
    invoicesLoading: false,
    exchangeRateLoading: false,
    commissionsLoading: false,
    paymentsLoading: false
  },
  reducers: {
    setSearchOption: (state, action) => {
      state.searchOption = action.payload
    },
    setFilterOption: (state, action) => {
      state.filterOption = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setCustomDate: (state, action) => {
      state.customDate = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessClients.pending, (state) => {
      state.businessClientsLoading = true
    })
    builder.addCase(getBusinessClients.fulfilled, (state, action) => {
      state.businessClientsLoading = false
      state.businessClients = action.payload.data.data
    })
    builder.addCase(getClientsBySellerDash.pending, (state) => {
      state.clientsBySellerDashLoading = true
    })
    builder.addCase(getClientsBySellerDash.fulfilled, (state, action) => {
      state.clientsBySellerDashLoading = false
      state.clientsBySellerDash = action.payload.data.data
    })
    builder.addCase(getClientsAdmin.pending, (state) => {
      state.clientsAdminLoading = true
    })
    builder.addCase(getClientsAdmin.fulfilled, (state, action) => {
      state.clientsAdminLoading = false
      state.clientsAdmin = action.payload.data.data
    })
    builder.addCase(getCashing.pending, (state) => {
      state.cashingLoading = true
    })
    builder.addCase(getCashing.fulfilled, (state, action) => {
      state.cashingLoading = false
      state.cashing = action.payload.data.data
    })
    builder.addCase(getInvoices.pending, (state) => {
      state.invoicesLoading = true
    })
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.invoicesLoading = false
      state.invoices = action.payload.data.data
    })
    builder.addCase(getCommisions.pending, (state) => {
      state.commissionsLoading = true
    })
    builder.addCase(getCommisions.fulfilled, (state, action) => {
      state.commissionsLoading = false
      state.comissions = action.payload.data
    })
    builder.addCase(getExchangeRates.pending, (state) => {
      state.exchangeRateLoading = true
    })
    builder.addCase(getExchangeRates.fulfilled, (state, action) => {
      state.exchangeRateLoading = false
      state.exchange_rate = action.payload.data.return
    })
    builder.addCase(getPayments.pending, (state) => {
      state.paymentsLoading = true
    })
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.paymentsLoading = false
      state.payments = action.payload.data
    })
    builder.addCase(getCertificatesClient.fulfilled, (state, action) => {
      state.certificatesClientLoading = false
      state.certificatesClient = action.payload.data.data
    })
    builder.addCase(getCertificatesClient.pending, (state) => {
      state.certificatesClientLoading = true
    })
    builder.addCase(getSalesPerSeller.pending, (state) => {
      state.salesPerSellerLoading = true
    })
    builder.addCase(getSalesPerSeller.fulfilled, (state, action) => {
      state.salesPerSellerLoading = false
      state.salesPerSeller = action.payload.data.data
    })
    builder.addCase(getCertificates.pending, (state) => {
      state.certificatesLoading = true
    })
    builder.addCase(getCertificates.fulfilled, (state, action) => {
      // spinner
      state.certificatesLoading = false
      // total
      state.totalCertificates = action.payload.data.total.total

      const data = action.payload.data
      // scheme, data
      state.certificates = [
        { label: "Emitido", value: data?.issued?.total ? data?.issued?.total : 0, color: "#00CFE8" },
        { label: "Con factura", value: data?.withInvoice?.total ? data.withInvoice.total : 0, color: "#7367F0" },
        { label: "Pagado", value: data?.withReference?.total ? data.withReference.total : 0, color: "#20C997" },
        { label: "Cancelado", value: data?.cancelled?.total ? data.cancelled.total : 0, color: "#EA5455" },
        { label: "Comisionado", value: data?.commissioned?.total ? data.commissioned.total : 0, color: "#4b4b4b" }
      ]
    })
    builder.addCase(getSales.pending, (state) => {
      state.salesLoading = true
    })
    builder.addCase(getSales.fulfilled, (state, action) => {
      // const filter = action.payload.params.period
      // if (filter === 'yearly') {
        // Average
        state.salesLoading = false
        state.salesAvg = action.payload?.data?.average ?? 0
        state.netPremiumAvg = action.payload?.data?.net_premium_average ?? 0
        state.totalNetPremium = action.payload?.data?.total_net_premium ?? 0
        //providers
        const providers = action.payload?.data?.providers
        state.berkleyMxn = providers?.berkley_mxn ?? 0
        state.berkleyUsd = providers?.berkley_usd ?? 0
        state.zurichMxn = providers?.zurich_mxn ?? 0
        state.zurichUsd = providers?.zurich_usd ?? 0
        //total
        state.totalSales = action.payload?.data?.total ?? 0
        
        if (action.payload.params.product === 4) {
          state.sales = {
            labels: action.payload?.data?.labels ?? [],
            datasets: [
                {
                    data: action.payload?.data?.amount ?? [],
                    label: 'Suma Asegurada',
                    borderColor: "#0D6EFD",
                    lineTension: 0.5,
                    pointStyle: 'circle',
                    backgroundColor: "#0D6EFD",
                    fill: false,
                    pointRadius: 1,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: 'transparent',
                    pointHoverBorderColor: '#fff',
                    pointHoverBackgroundColor: "#0D6EFD",
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5
                }
            ]
          }
        } else {
          state.sales = {
            labels: action.payload?.data?.labels ?? [],
            datasets: [
                {
                    data: action.payload?.data?.amount ?? [],
                    label: 'Suma Asegurada',
                    borderColor: "#0D6EFD",
                    lineTension: 0.5,
                    pointStyle: 'circle',
                    backgroundColor: "#0D6EFD",
                    fill: false,
                    pointRadius: 1,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: 'transparent',
                    pointHoverBorderColor: '#fff',
                    pointHoverBackgroundColor: "#0D6EFD",
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5
                },
                {
                    data: action.payload?.data?.net_premium ?? [],
                    label: 'Prima neta',
                    borderColor: "#00CFE8",
                    lineTension: 0.5,
                    pointStyle: 'circle',
                    backgroundColor: "#00CFE8",
                    fill: false,
                    pointRadius: 1,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: 'transparent',
                    pointHoverBorderColor: '#fff',
                    pointHoverBackgroundColor: "#00CFE8",
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5
                }
            ]
          }
        }
      // }
    })
  }
})

export const dashboardFilters = DashboardFiltersSlice.actions
export default DashboardFiltersSlice.reducer

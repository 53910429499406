// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Axios Imports
import api from '@src/api/config'
import { showModalSuccess } from "./modalTypeSuccess"
import moment from "moment"
import { hideModalCustom, setLoading } from "../../src/redux/modalTypeCustom"
import { showModalFullCustom } from "../../src/redux/modalTypeFullCustom"
import { Success } from "../../src/assets/images/icons"
import { getInvoices } from "../../src/views/Invoices/store/invoices"
/** */
moment.locale('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
}
)
export const getUsoCfdis = createAsyncThunk('fiscal/usoCdfi', async (params = null) => {
  const response = await api.get(`api/uso_cfdi`, params)
  return {
    data: response.data
  }
})
export const getCurrency = createAsyncThunk('currency/get', async () => {
  const response = await api.get(`api/currency`)
  return {
    data: response.data
  }
})
/**
 * Get subgroups
 */
export const getSubGroups = createAsyncThunk('subgroups/get', async () => {
  const response = await api.get(`api/subgroups`)
  return {
    data: response.data
  }
})
/**
 * @param {Object} id
 * @description Update subgroup in invoice  
 * @returns {Object} response.data
 */
export const updateSubGroup = createAsyncThunk('subgroups/update', async (params, { dispatch }) => {  
  const response = await api.post(`api/subgroups/updateInvoice/${params.id}`, { subgroup_id: params.sid })
  if (response.data.status === 'success') {
    dispatch(getInvoices(params.params))
  }
  return {
    data: response.data
  }
})
/**
 * @param {Object} data
 * @description Set new subgroup
 * @returns {Object} response.data
 */
export const setNewSubGroup = createAsyncThunk('subgroups/setNew', async (data, { dispatch }) => {
  const response = await api.post(`api/subgroups`, { subgroup_name: data.subgroupname, invoice_id: data.id })
  if (response.data.status === 'success') {
    dispatch(updateSubGroup({ id: data.id, sid: response.data.subgroup.id, params: data.params }))
    dispatch(getSubGroups())
    //dispatch(getInvoices(data.params))
  }
  return {
    data: response.data
  }
})
/**
  * @descriptio get invoces with subgroups
  * @returns {Object} response.data
 */
export const getInvoicesWithSubGroups = createAsyncThunk('subgroups/getInvoices', async (params) => {
  const response = await api.get(`api/subgroups/all`, { params })
  return {
    data: response.data
  }
})
/**
 ** delete subgroup in invoice 
 */
export const deleteSubGroup = createAsyncThunk('subgroups/delete', async (data, { dispatch }) => {
  try {    
    const response = await api.delete(`api/subgroups/deleteInvoice/${data.id}`)
    if (response.data.status === 'success') {
      dispatch(hideModalCustom())
      await dispatch(showModalFullCustom({
        icon: <Success />,
        message: `Factura eliminada del sub-grupo ${data.sub} con éxito.`        
      }))
       dispatch(setLoading(false))
       dispatch(getInvoices(data.params))
       dispatch(getInvoicesWithSubGroups(data.paramsSubG))
      return {
        data: response.data
      }
    } else {
      dispatch(setLoading(false))
      return {
        data: response.data
      }
    }
  } catch (error) {
    console.error("❌ | file: fiscal.js:113 deleteSubGroup | error:", error)
  }
})
export const createDatosFiscalCliente = createAsyncThunk('fiscal/storeClient', async (data, { dispatch }) => {
  const response = await api.post(`api/seller/clients`, data)
  if (response.data.status === 'success') {
    const url = `/seller/clients`
    dispatch(showModalSuccess({ message: response.data.message, url }))
  }
  return {
    data: response.data
  }
})
export const getTaxSystems = createAsyncThunk('fiscal/getTaxSystems', async (options) => {
  const response = await api.get(`api/tax_systems`, { params: options })
  return {
    data: response.data
  }
})
export const getPaymentForm = createAsyncThunk('fiscal/getPaymentForm', async () => {
  const response = await api.get(`api/forma_pago`)
  return {
    data: response.data
  }
})
export const fiscalSlice = createSlice({
  name: "fiscal",
  initialState: {
    usoCfdi: [],
    origin: [
      {
        label: "Mexicana",
        value: "mexican"
      },
      {
        label: "Extranjera",
        value: "foreign"
      }
    ],
    billingTypes: ["moral", "physical"],
    billing: ["generate_cert", "generate_payment_reference"],
    periods: [
      { label: "Semanal", value: "week" },
      { label: "Quincenal", value: "fortnight" },
      { label: "Mensual", value: "month" }
    ],
    days: [],
    product_status: [
      { label: "Nuevo", value: "new" },
      { label: "Usado", value: "used" }
    ],
    currency: [],
    taxSystems: [], // regimen fiscal
    paymentForms: []
  },
  reducers: {
    changeDaysFromPeriod: (state, action) => {
      const period = action.payload.period ? action.payload.period : "week"
      let diaSelected = 7
      if (period === "fortnight") {
        diaSelected = 15
      }
      if (period === "month") {
        diaSelected = 30
      }
      const values = []
      for (let i = 1; i <= diaSelected; i++) {
        values.push({
          label: `${diaSelected === 7 ? moment().isoWeekday(i).format('dddd') : i}`,
          value: i
        })
      }
      if (period === "month") {
        values.push({
          label: `Último día del mes`,
          value: 31
        })
      }
      state.days = values
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsoCfdis.fulfilled, (state, action) => {
      state.usoCfdi = action.payload.data
    })
    builder.addCase(getCurrency.fulfilled, (state, action) => {
      state.currency = action.payload.data
    })
    builder.addCase(getSubGroups.fulfilled, (state, action) => {
      state.subgroups = action.payload.data
    })
    builder.addCase(getInvoicesWithSubGroups.fulfilled, (state, action) => {
      state.subgroupsAndInvoice = action.payload.data
    })
    builder.addCase(getTaxSystems.fulfilled, (state, action) => {
      state.taxSystems = action.payload.data
    })
    builder.addCase(getPaymentForm.fulfilled, (state, action) => {
      // console.debug("getPaymentForm", action.payload)
      state.paymentForms = action.payload.data
    })
  }
})
export const { changeDaysFromPeriod } = fiscalSlice.actions
export default fiscalSlice.reducer

// ** Redux Imports
import rootReducer from "./rootReducer"
import { configureStore } from "@reduxjs/toolkit"
import reactotron from "../ReactotronConfig"

/**
 * @typedef {Object} ConfigStore
 * @property {import("./rootReducer").RootReducer} reducer - El reducer raíz del store.
 * @property {Function} middleware - Función que devuelve los middleware del store.
 * @property {any[]} enhancers - Mejoras aplicadas al store.
 * @property {import("redux").Dispatch<import("redux").AnyAction>} dispatch - Función de llamada de acciones.
 */

/**
 * @type {ConfigStore}
 */
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  },
  enhancers: [reactotron.createEnhancer()]
})

export { store }

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getSellerClients = createAsyncThunk(
  "seller/getClients",
  async (params, { getState }) => {
    const state = getState()
    const product = state.products.productSelected.value
    params.product = product
    const response = await api.get(`api/clients`, { params })
    return {
      params,
      data: response.data
    }
  }
)

// export const getSellerClientById = createAsyncThunk(
//   "seller/getClientById",
//   async (id) => {
//     const response = await api.get(`api/seller/client/${id}`)
//     return {
//       data: response.data
//     }
//   }
// )

export const getClientById = createAsyncThunk("seller/getClientById", async (id) => {
  // console.debug("getClientById", id)
  const response = await api.get(`api/clients/${id}`)
  return {
    data: response.data
  }
})

export const sellerClientsSlice = createSlice({
  name: "sellerClients",
  initialState: {
    data: null,
    defaultValues: null,
    total: 0,
    // show details
    client: [],
    seller: [],
    taxData: [],
    managers: []
  },
  reducers: {
    resetClients: (state) => {
      state.allClients = []
      state.client = []
      state.seller = []
      state.taxData = []
      state.managers = []
      state.meta = {}
      state.total = 0
      state.message = ""
      state.status = {}
      state.defaultValues = null
    },
    clearSaved: (state) => {
      state.saved = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSellerClients.fulfilled, (state, action) => {
      // console.debug("getSellerClients", action.payload)
      const payload = action.payload.data
      state.data = payload.data
      state.total = payload.meta.total
    })
    builder.addCase(getClientById.fulfilled, (state, action) => {
      // console.debug("getSellerClientById", action.payload)
      // const payload = action.payload.data.data
      // state.defaultValues = {
      //   // personal
      //   apellido_paterno: payload.last_name,
      //   apellido_materno: payload.last_name_2,
      //   nombres: payload.name,
      //   correo_electronico: payload.email,
      //   telefono: payload.phone,
      //   // fiscal
      //   procedencia: payload.taxData.origin,
      //   billing_type: payload.taxData.type,
      //   razon_social: payload.taxData.business_name,
      //   nombre_fiscal: payload.taxData.names,
      //   primer_apellido: payload.taxData.last_name,
      //   segundo_apellido: payload.taxData.last_name_2,
      //   pais: payload.taxData.country,
      //   estado: payload.taxData.state,
      //   ciudad: payload.taxData.city,
      //   codigo_postal: payload.taxData.postal_code,
      //   colonia: payload.taxData.suburb,
      //   calle_numero: payload.taxData.address,
      //   tax_id: payload.taxData.taxid,
      //   rfc: payload.taxData.rfc,
      //   cuota_base: "27%", // TODO move this value to config file
      //   billing: payload.taxData.billing_type,
      //   periodo_corte: payload.taxData.billing_period,
      //   dia: payload.taxData.billing_day,
      //   uso_cfdi: payload.taxData.uso_cfdi
      // }

      // copy from users/clients/store/index
      const payload = action.payload.data
      state.client = payload.data[0]
      state.seller = payload.data.seller
      state.taxData = payload.data.taxData
      state.managers = payload.data.managers
    })
  }
})

export const { resetClients, clearSaved } = sellerClientsSlice.actions
export default sellerClientsSlice.reducer

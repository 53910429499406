/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { Fragment } from "react"
import { ToastSuccess } from "../../../assets/images/icons"
import SuccessToast from "../../../components/common/toast/SuccessToast"
import ErrorToast from "../../../components/common/toast/ErrorToast"
import { hideModalDelete, setLoading } from "../../../redux/modalTypeDelete"
import { hideModalCustom } from "../../../redux/modalTypeCustom"
import { toast } from 'react-toastify'
import { clearPage } from "../../Certs/store/certs"
// ** Axios Imports
import api from "@src/api/config"
import fileDownload from 'js-file-download'
import _ from 'lodash'
import { showModalSuccess } from "../../../redux/modalTypeSuccess"

export const getExchangeRate = createAsyncThunk("invoices/get_exchange_rate", async () => {
  const response = await api.get(`api/exchange_rate`)
  return {
    data: response.data
  }
})

export const getInvoices = createAsyncThunk("invoices/get", async (params, { getState }) => {
  try {
    const state = getState()
    const pages = state.invoices.pages
    const page = params.page ?? 1
    const aux = state.invoices.params
    const { page: pageAux, ...current_params } = aux
    const { page: newPageAux, ...new_params } = params
    const product = state.products.productSelected.value
    params.product = params.subgroup_id ? null : product
    // console.log('Current params:', current_params)
    // console.log('New params:', new_params)
    // console.debug('Pages:', pages)
    // console.debug('Length:', Object.keys(pages).length)
    // console.debug(page, 'is in pages?', pages.hasOwnProperty(page))
    // console.debug('Current params are equal to New Params?', _.isEqual(current_params, new_params))
    if (Object.keys(pages).length === 0 || !pages.hasOwnProperty(page) || !_.isEqual(current_params, new_params) || params.force) {
      const response = await api.get(`api/invoices`, { params })
      const { force, ...auxParams } = params
      return {
        params: auxParams,
        data: response.data
      }
    } else if (pages.hasOwnProperty(page)) {
      // for (let i = page + 1; i <= page + 2; i++) {
      //   dispatch(getPage({ params, page: i }))
      // }
      return {
        params,
        data: pages[page]
      }
    }
  } catch (error) {
    console.error("❌ | file: invoices.js:58 | error:", error)
  }
})
export const getInvoice = createAsyncThunk("invoices/getById", async (id) => {
  const response = await api.get(`api/invoices/${id}`)
  return {
    data: response.data
  }
})

export const getFailedScheduledInvoice = createAsyncThunk("invoices/scheduled/getById", async (id) => {
  const response = await api.get(`api/invoices/failed_scheduled/${id}`)
  return {
    data: response.data
  }
})

export const getInvoicesSuggestions = createAsyncThunk("invoices/get/all", async (params, { getState }) => {
  const state = getState()
  params.product = state.products.productSelected?.value
  const response = await api.get(`api/invoices/all/list`, { params })
  return {
    params,
    data: response.data
  }
})

export const getScheduledFailedInvoices = createAsyncThunk("invoices/failed_scheduled", async (params = {}, { getState }) => {
  const state = getState()
  const { productSelected } = state.products
  params.product = productSelected?.value
  const response = await api.get(`api/invoices/failed_scheduled`, { params })
  return {
    params,
    data: response.data
  }
})

export const getScheduledFailedSuggestions = createAsyncThunk("invoices/failed_scheduled_suggestions", async (params) => {
  const response = await api.get(`api/invoices/failed_scheduleds/suggestions`, { params })
  return {
    params,
    data: response.data
  }
})

export const getScheduledInvoices = createAsyncThunk("invoices/scheduled", async (params = {}, { getState }) => {
  const state = getState()
  const { productSelected } = state.products
  params.product = productSelected?.value
  const response = await api.get(`api/invoices/scheduled`, { params })
  return {
    params,
    data: response.data
  }
})

export const getScheduledSuggestions = createAsyncThunk("invoices/scheduled_suggestions", async (params) => {
  const response = await api.get(`api/invoices/scheduled/suggestions`, { params })
  return {
    params,
    data: response.data
  }
})

export const getPaymentMethods = createAsyncThunk("invoices/payment_methods", async () => {
  const response = await api.get(`api/invoices/payment_methods`)
  return {
    data: response.data
  }
})

export const getPreviewInvoice = createAsyncThunk("preview/invoice", async (id) => {
  const response = await api.get(`api/invoices/file/${id}`)
  return {
    data: response
  }
})

export const getDownloadInvoice = createAsyncThunk("preview/invoice", async (id) => {
  const response = await api.get(`api/invoices/download/${id}`, { responseType: 'blob' })
    .then((res) => {
      fileDownload(res.data, `factura_${id}.pdf`)
    })
  return {
    data: response.data
  }
})

export const getDownloadFailedScheduledInvoice = createAsyncThunk("preview/invoice", async (id) => {
  const response = await api.get(`api/invoices/file_failed_scheduled/${id}`, { responseType: 'blob' })
    .then((res) => {
      fileDownload(res.data, `factura_programada_${id}_error.pdf`)
    })
  return {
    data: response.data
  }
})
// Special endpoint to certificates billing
export const createCertInvoice = createAsyncThunk("certs/invoice/create", async ({ payload, history }, { dispatch, getState }) => {
  const state = getState()
  const product = state.products.productSelected.value
  payload.product_id = product
  const response = await api.post(`api/invoices`, payload)
  dispatch(hideModalCustom())
  if (response.data.status === 'success') {
    dispatch(clearPage())
    toast(<SuccessToast message={response.data.message} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    const id = response.data.data.id
    history.push(`/invoices/show/${id}`)
  }
  return {
    data: response.data
  }
})

export const updateInvoice = createAsyncThunk("invoices/update", async ({ id, params }, { dispatch, getState }) => {
  dispatch(setLoading(true))
  try {
    const response = await api.put(`api/invoices/${id}`, params)
    dispatch(setLoading(false))
    dispatch(clearPage())
    toast(<SuccessToast message={"Factura actualizada"} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    const state = getState()
    dispatch(getInvoices(state.params))
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    dispatch(setLoading(false))
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible actualizar la factura"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible actualizar la factura")
  }

})

/**
 * Only accountants are allowed to cancel invoices
 */
export const cancelInvoice = createAsyncThunk("invoices/cancel", async ({ id, params }, { dispatch, getState }) => {
  try {
    dispatch(setLoading(true))
    const response = await api.delete(`api/invoices/${id}`, { params })
    dispatch(setLoading(false))
    dispatch(hideModalDelete())
    dispatch(clearPage())
    toast(<SuccessToast message={"Factura cancelada"} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    const state = getState()
    dispatch(getInvoices(state.params))
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    dispatch(setLoading(false))
    dispatch(hideModalDelete())
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible cancelar la factura"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible cancelar la factura")
  }
})

/**
 * Sellers and admins can send a request to accountant for cancel invoices
 */
export const requestToCancelInvoice = createAsyncThunk("invoices/requestCancel", async ({ id, params }, { dispatch, getState }) => {
  try {
    dispatch(setLoading(true))
    const response = await api.delete(`api/request_cancel/${id}`, { params })
    dispatch(setLoading(false))
    dispatch(hideModalDelete())
    toast(<SuccessToast message={"Factura cancelada"} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    const state = getState()
    dispatch(getInvoices(state.params))
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    dispatch(setLoading(false))
    dispatch(hideModalDelete())
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible cancelar la factura"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible cancelar la factura")
  }
})

export const toggleUnlockInvoice = createAsyncThunk("invoices/unlockInvoice", async ({ id, type }, { dispatch, getState }) => {
  // dispatch(hideModalCustom())
  // console.debug('Unlock Invoice')
  try {
    dispatch(setLoading(true))
    const response = await api.post(`api/invoices/${id}/toggle_unlock`)
    dispatch(setLoading(false))
    dispatch(hideModalCustom())
    toast(<SuccessToast message={`Factura ${type}`} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    const state = getState()
    dispatch(getInvoices({ ...state.invoices.params, force: true }))
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err)
    dispatch(setLoading(false))
    dispatch(hideModalCustom())
    toast(<ErrorToast message={err.response.data?.message ?? `No fue posible ${type} la factura`} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? `No fue posible ${type} la factura`)
  }
})


export const payManual = createAsyncThunk("invoices/pay_manual", async ({ id, file }, { dispatch, getState }) => {
  try {
    const data = new FormData()
    data.append('file', file)
    const response = await api.post(`api/invoices/${id}/manual_payment`, data, { headers: { 'Content-Type': 'multipart/form-data', "X-Requested-With": "XMLHttpRequest", enctype: "multipart/form-data" } })
    dispatch(setLoading(false))
    toast(<SuccessToast message={"Factura pagada manualmente"} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    const state = getState()
    dispatch(getInvoices(state.invoices.params))
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    dispatch(setLoading(false))
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible pagar la factura"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible cancelar la factura")
  }
})

export const payManualBase64 = createAsyncThunk("invoices/pay_manual_base64", async ({ id, file, payment_method }, { dispatch, getState }) => {
  try {
    const response = await api.post(`api/invoices/${id}/manual_payment`, { file, payment_method })
    dispatch(setLoading(false))
    toast(<SuccessToast message={"Factura pagada manualmente"} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    const state = getState()
    dispatch(getInvoices(state.invoices.params))
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    dispatch(setLoading(false))
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible pagar la factura"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible cancelar la factura")
  }
})

export const sendFailedScheduledByEmail = createAsyncThunk(
  "invoices/sendFailedScheduledByEmail",
  async ({ scheduled_invoice_id, params }) => {
    try {
      const response = await api.post(`api/invoices/send_failed_scheduled_mail/${scheduled_invoice_id}`, {
        ...params
      })
      toast(<SuccessToast message={response.data.message} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
      return {
        data: response.data,
        params
      }
    } catch (err) {
      toast(<ErrorToast message={"Error al enviar el correo"} />, { icon: null, hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    }
  }
)

export const sendInvoiceByEmail = createAsyncThunk(
  "invoices/sendByEmail",
  async ({ invoice_id, params }) => {
    try {
      const response = await api.post(`api/invoices/send_mail/${invoice_id}`, {
        ...params
      })
      toast(<SuccessToast message={response.data.message} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
      return {
        data: response.data,
        params
      }
    } catch (err) {
      toast(<ErrorToast message={"Error al enviar el correo"} />, { icon: null, hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    }
  }
)

export const createPaymentLink = createAsyncThunk("invoices/paymentLink", async (params, { dispatch, getState }) => {
  try {
    const state = getState()
    params.product = state.products.productSelected?.value
    const response = await api.post(`api/payment_link`, params)
    dispatch(setLoading(false))
    return {
      data: response.data
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    dispatch(setLoading(false))
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible generar el enlace de pago"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible generar el enlace de pago")
  }
})

export const sendPaymentLinkToEmail = createAsyncThunk("invoices/sendPaymentLinkToEmail", async (params, { dispatch }) => {
  try {
    const response = await api.post(`api/payment_link/send`, { ...params })

    dispatch(setLoading(false))
    if (response.data.status === 'success') {
      toast(<SuccessToast message={response.data.message} />, { icon: <ToastSuccess />, hideProgressBar: true, className: "toast-success", autoClose: 5000 })
    }
    return {
      data: response.data,
      params
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    dispatch(setLoading(false))
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible enviar enlace de pago"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible enviar el enlace de pago")
  }
})

export const getInvoicesFromPaymentCancelled = createAsyncThunk("invoices/getInvoicesFromPaymentCancelled", async (params) => {
  try {
    const response = await api.get(`api/payments/invoices`, { params })
    return {
      data: response.data,
      params
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible cancelar el pago"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible cancelar el pago")
  }
})

export const sendFilesByEmail = createAsyncThunk('invoices/email', async ({ payload, toggleHandler }, { dispatch }) => {
  try {
    const response = await api.post(`api/files/email`, payload)
    if (response.data.status === 'success') {
      toggleHandler(false)
      dispatch(showModalSuccess({ message: 'Correo electrónico enviado con éxito.' }))
    }
    return {
      data: response.data,
      payload
    }
  } catch (err) {
    // console.log('Error:', err.response.data?.message)
    toast(<ErrorToast message={err.response.data?.message ?? "No fue posible enviar el correo"} />, { hideProgressBar: true, className: "toast-danger", autoClose: 5000 })
    throw new Error(err.response.data?.message ?? "No fue posible enviar el correo")
  }
})

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState: {
    invoices: [], // data from backend
    pages: {},
    scheduled: [],
    error: [],
    params: {}, // last params
    paramsScheduled: {},
    paramsError: {},
    preview_url: null,
    invoice: null, // invoice created
    invoiceDeleted: null, // invoice deleted
    invoiceUpdated: null,
    invoicePaid: null,
    payment_methods: [],
    error: null,
    response: null,
    showInvoice: {},
    autocompleteValues: [],
    autocompleteValuesScheduled: [],
    autocompleteValuesFailedScheduled: [],
    exchange_rate: null,
    paymentLink: '',
    rowSelected: null,
    table_status: 'idle',
    send_email_loading: 'idle'
  },
  reducers: {
    clearInvoices: (state) => {
      // console.debug("execute clearInvoices")
      state.invoices = [] // data from backend
      state.scheduled = []
      state.scheduledFailed = []
      state.preview_url = null
      state.invoice = null// invoice created
      state.invoiceDeleted = null
      state.invoiceUpdated = null
      state.invoicePaid = null
      state.response = null
      state.paymentLink = ''
    },
    clearInvoicePaid: (state) => {
      state.invoicePaid = null
    },
    invoiceSelected: (state, action) => {
      state.showInvoice = action.payload.invoice
      localStorage.setItem('show_invoice', JSON.stringify(action.payload.invoice))
    },
    getInvoiceSelected: (state) => {
      const showInvoice = JSON.parse(localStorage.getItem('show_invoice'))
      state.showInvoice = showInvoice
    },
    setRowSelected: (state, action) => {
      state.rowSelected = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoices.pending, (state, action) => {
      const { page: pageAux, ...current_params } = state.params
      const { page, ...new_params } = action.meta?.arg
      if (_.isEqual(current_params, new_params)) {
        if (!state.pages.hasOwnProperty(page)) state.table_status = 'loading'
      } else state.table_status = 'loading'
    })
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.table_status = 'sucessfull'
      const { page: pageAux, ...current_params } = state.params
      const { page: newPageAux, ...new_params } = action.payload?.params
      state.invoices = action.payload.data
      state.params = action.payload.params
      const page = action.payload?.params?.page ?? 1
      if (_.isEqual(current_params, new_params)) {
        state.pages[page] = action.payload?.data
      } else {
        state.pages = {}
        state.pages[page] = action.payload?.data
      }
    })
    builder.addCase(getInvoices.rejected, (state, action) => {
      // console.error(action)
      state.table_status = 'error'
    })
    builder.addCase(sendFilesByEmail.pending, (state) => {
      state.send_email_loading = 'loading'
    })
    builder.addCase(sendFilesByEmail.fulfilled, (state) => {
      state.send_email_loading = 'successfull'
    })
    builder.addCase(sendFilesByEmail.rejected, (state) => {
      state.send_email_loading = 'error'
    })
    builder.addCase(getScheduledInvoices.fulfilled, (state, action) => {
      state.scheduled = action.payload.data
      state.paramsScheduled = action.payload.params
    })
    builder.addCase(getScheduledFailedInvoices.fulfilled, (state, action) => {
      state.scheduledFailed = action.payload.data
      state.paramsError = action.payload.params
    })
    builder.addCase(getInvoicesSuggestions.fulfilled, (state, action) => {
      // console.debug("get getInvoicesSuggestions", action.payload)
      state.autocompleteValues = action.payload.data
    })
    builder.addCase(getScheduledSuggestions.fulfilled, (state, action) => {
      // console.debug("get getScheduledSuggestions", action.payload)
      state.autocompleteValuesScheduled = action.payload.data
    })
    builder.addCase(getScheduledFailedSuggestions.fulfilled, (state, action) => {
      state.autocompleteValuesFailedScheduled = action.payload.data
    })
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.payment_methods = action.payload.data
    })
    builder.addCase(createCertInvoice.fulfilled, (state, action) => {
      state.invoice = action.payload.data.data
      state.pages[1] = {}
    })
    builder.addCase(getPreviewInvoice.fulfilled, (state, action) => {
      state.preview_url = action.payload.data.data
    })
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state.invoiceUpdated = action.payload.data
    })
    builder.addCase(cancelInvoice.fulfilled, (state, action) => {
      state.invoiceDeleted = action.payload.data
    })
    builder.addCase(toggleUnlockInvoice.fulfilled, (state, action) => {
      state.pages = {}
    })
    builder.addCase(payManual.fulfilled, (state, action) => {
      state.invoicePaid = action.payload.data
      state.pages[1] = {}
    })
    builder.addCase(payManualBase64.fulfilled, (state, action) => {
      state.invoicePaid = action.payload.data
    })
    builder.addCase(payManualBase64.rejected, (state, action) => {
      state.scheduledFailed = action.payload
      state.pages = {}
    })
    builder.addCase(sendInvoiceByEmail.fulfilled, (state, action) => {
      state.response = action.payload.data
    })
    builder.addCase(sendFailedScheduledByEmail.fulfilled, (state, action) => {
      state.response = action.payload.data
    })
    builder.addCase(getExchangeRate.fulfilled, (state, action) => {
      // console.debug("getExchangeRate", action.payload.data)
      state.exchange_rate = action.payload.data
    })
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      state.showInvoice = action.payload.data.data
    })
    builder.addCase(getFailedScheduledInvoice.fulfilled, (state, action) => {
      state.showInvoice = action.payload.data.data
    })
    builder.addCase(createPaymentLink.fulfilled, (state, action) => {
      // console.debug("createPaymentLink", state, action.payload)
      state.paymentLink = action.payload.data.redirect_url
    })
    builder.addCase(getInvoicesFromPaymentCancelled.fulfilled, (state, action) => {
      // console.debug("getInvoicesFromPaymentCancelled", state, action.payload)
      state.invoices = action.payload.data
      state.params = action.payload.params
    })
  }
})

export const { clearInvoices, clearInvoicePaid, invoiceSelected, getInvoiceSelected, setRowSelected } = invoiceSlice.actions
export default invoiceSlice.reducer

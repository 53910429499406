// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt"
import api from "@api/config"
import { showLogoutModal } from "./modalSessionExpired"
// import { useSelector } from 'react-redux'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem("userData")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const initialAccessToken = () => {
  const item = window.localStorage.getItem("token")
  //** Parse stored json or if none return initialValue
  return item ? item : null
}

const initialRefreshAccessToken = () => {
  const item = window.localStorage.getItem("refreshToken")
  //** Parse stored json or if none return initialValue
  return item ? item : null
}

const initialLimit = () => {
  const item = window.localStorage.getItem("limit")
  //** Parse stored json or if none return initialValue
  return item ? item : null
}

export const revokeToken = createAsyncThunk(
  "autentication/logout",
  async () => {
    const response = await api.post(`api/revoke-token`)
    return {
      data: response.data
    }
  }
)
export const getMe = createAsyncThunk("autentication/me", async () => {
  const response = await api.get(`api/me`)
  return {
    data: response.data
  }
})

export const refreshToken = createAsyncThunk(
  "autentication/refresh",
  async (refreshToken, { dispatch, getState }) => {
    const state = getState()
    const response = await api.post(`auth/refresh`, { refreshToken })
    if (response.data.status === "error") {
      dispatch(showLogoutModal())
    } else {
      const data = {
        limit: response.data.data.limit,
        accessToken: response.data.data.token,
        refreshToken: response.data.data.refresh_token
          ? response.data.data.refresh_token
          : null
      }

      state[config.storageTokenKeyName] = data.accessToken
      state[config.storageRefreshTokenKeyName] = data.refreshToken
      localStorage.setItem(config.storageTokenKeyName, data.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, data.refreshToken)
      localStorage.setItem("limit", data.limit)
    }
    return {
      data: response.data
    }
  }
)

/**
 * @typedef {Object} User
 * @property {number} id
 * @property {string} full_name
 * @property {string} name
 * @property {string | null} last_name
 * @property {string | null} last_name_2
 * @property {string} email
 * @property {number} city_id
 * @property {number} city.id
 * @property {string} city.name
 * @property {number} city.state_id
 * @property {string} phone
 * @property {string} role
 * @property {boolean} openpay
 * @property {boolean} stripe
 * @property {any[]} products
 * @property {number} massive_certificates
 * @property {any[]} ability
 * @property {number} role_id
 * @property {string} rfc
 * @property {string} birthdate
 * @property {number} base_fee
 * @property {number} tax_system.id
 * @property {string} tax_system.code
 * @property {string} tax_system.description
 * @property {number} tax_system.moral
 * @property {number} tax_system.physical
 * @property {number} tax_system.seller
 * @property {string} created_at
 * @property {number} is_root_admin
 */

/**
 * @typedef {Object} AuthState
 * @property {User} userData
 * @property {number} limit
 * @property {string} accessToken
 * @property {string} refreshToken
 */

/**
 * @type {AuthState}
 */
const initialState = {
  userData: initialUser(),
  accessToken: initialAccessToken(),
  refreshToken: initialRefreshAccessToken(),
  limit: initialLimit()
}

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload.accessToken
      state[config.storageRefreshTokenKeyName] = action.payload.refreshToken
      localStorage.setItem("userData", JSON.stringify(action.payload))
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        action.payload.refreshToken
      )
      localStorage.setItem("limit", action.payload.limit)
    },
    handleLogout: (state) => {
      // console.debug("handleLogout")
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData")
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.removeItem("limit")
      // ** Clear localStorage
      localStorage.clear()
      // ** Reload page
      window.location.reload(true)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(revokeToken.fulfilled, (state) => {
      // console.debug("revokeToken")
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData")
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    })
    builder.addCase(getMe.fulfilled, (state, action) => {
      // console.log("data", action.payload.data.data)
      const user = action.payload.data.data
      state.userData.last_name = user.last_name
      state.userData.last_name_2 = user.last_name_2
      state.userData.name = user.name
      state.userData.phone = user.phone
      state.userData.birthdate = user.birthdate
      state.userData.city = user.city
      state.userData.email = user.email
      state.userData.tax_system = user.tax_system
      state.userData.sales_fee = user.sales_fee
      state.userData.rfc = user.rfc
      state.userData.full_name = user.full_name
      state.userData.clabe = user.clabe
      // queda pendiente la CLABE porque no viene en el login ni en el getMe
      localStorage.setItem("userData", JSON.stringify(state.userData))
    })
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { useHistory } from "react-router-dom"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"

export const getAdmin = createAsyncThunk(
  "admins/getById",
  async (id) => {
    const response = await api.get(`api/administrators/${id}`)
    return {
      data: response.data
    }
  }
)

export const deleteAdmin = createAsyncThunk(
  "admins/delete",
  async (id, { dispatch }) => {
    const response = await api.delete(`api/administrators/${id}`)
    if (response.data.status === 'success') {
      dispatch(showModalSuccess({ message: response.data.message }))
      dispatch(getAdmin(id))
      useHistory.push('/users/admins')
    }
    return {
      data: response.data
    }
  }
)

export const activeAdmin = createAsyncThunk(
  "admins/active",
  async (id, { dispatch }) => {
    const response = await api.patch(`api/active/administrators/${id}`)
    if (response.data.status === 'success') {
      dispatch(showModalSuccess({ message: response.data.message }))
      dispatch(getAdmin(response.data.data.role_id))
    }
    return {
      data: response.data
    }
  }
)

export const AdminsSlice = createSlice({
  name: "admins",
  initialState: {
    data: null,
    total: 0,
    defaultValues: null,
    admin: null
  },
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(deleteAdmin.fulfilled, (state, action) => {
      // console.debug("deleteAdmin", action.payload)
      state.data = action.payload.data
    })
    builder.addCase(activeAdmin.fulfilled, (state, action) => {
      // console.debug("activeAdmin", action.payload)
      state.data = action.payload.data
    })
    builder.addCase(getAdmin.fulfilled, (state, action) => {
      // console.debug("getAdmin", action.payload)
      state.admin = action.payload.data.data[0]
    })
  }
})

export default AdminsSlice.reducer
